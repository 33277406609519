import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">ChiroSport South Africa</span>
              <img 
                className="h-12 w-auto sm:h-16"
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-586KQLSQZIkaqnzNCBxiZHsGfKVwzZ.png" 
                alt="ChiroSport South Africa Logo"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-chiro-blue hover:text-chiro-red hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-chiro-blue"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <Link to="/" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Home</Link>
            <Link to="/payment" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Payment</Link>
            <Link to="/terms" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Terms</Link>
            <a href="mailto:admin@chirosport.co.za" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Contact</a>
          </nav>
        </div>
      </div>

      {isMenuOpen && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/logo-586KQLSQZIkaqnzNCBxiZHsGfKVwzZ.png"
                    alt="ChiroSport South Africa"
                  />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-chiro-blue hover:text-chiro-red hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-chiro-blue"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <Link to="/" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Home</Link>
                  <Link to="/payment" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Payment</Link>
                  <Link to="/terms" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Terms</Link>
                  <a href="mailto:admin@chirosport.co.za" className="text-base font-medium text-chiro-blue hover:text-chiro-red">Contact</a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  );
}