import React, { useEffect } from 'react';

const NotifyPage = () => {
  useEffect(() => {
    const handlePayfastNotification = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const paymentData = Object.fromEntries(urlParams.entries());

      try {
        const response = await fetch('/api/payment-notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...paymentData,
            status: paymentData.payment_status,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to process payment notification');
        }

        console.log('Payment notification processed successfully');
      } catch (error) {
        console.error('Error processing payment notification:', error);
      }
    };

    handlePayfastNotification();
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Payment Notification
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            This page is for payment notifications. No user action is required.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotifyPage;