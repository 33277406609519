import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PaymentPortal from './components/PaymentPortal';
import TermsAndConditions from './components/TermsAndConditions';
import ReturnPage from './components/ReturnPage';
import CancelPage from './components/CancelPage';
import NotifyPage from './components/NotifyPage';
import Layout from './components/Layout';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PaymentPortal />} />
        <Route path="payment" element={<PaymentPortal />} />
        <Route path="terms" element={<TermsAndConditions />} />
        <Route path="return" element={<ReturnPage />} />
        <Route path="cancel" element={<CancelPage />} />
        <Route path="notify" element={<NotifyPage />} />
      </Route>
    </Routes>
  );
}

export default App;