import React from 'react';

export default function TermsAndConditions() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <main className="flex-grow py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-extrabold text-chiro-blue mb-6">Terms and Conditions</h2>
          
          <div className="prose prose-chiro-blue">
            <h3 className="text-chiro-blue">1. Introduction</h3>
            <p>
              These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, ChiroSport accessible at www.chirosport.co.za.
            </p>
            <p>
              These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
            </p>

            <h3 className="text-chiro-blue">2. Intellectual Property Rights</h3>
            <p>
              Other than the content you own, under these Terms, ChiroSport and/or its licensors own all the intellectual property rights and materials contained in this Website.
            </p>
            <p>
              You are granted limited license only for purposes of viewing the material contained on this Website.
            </p>

            <h3 className="text-chiro-blue">3. Restrictions</h3>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
              <li>publishing any Website material in any other media;</li>
              <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
              <li>publicly performing and/or showing any Website material;</li>
              <li>using this Website in any way that is or may be damaging to this Website;</li>
              <li>using this Website in any way that impacts user access to this Website;</li>
              <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
              <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
              <li>using this Website to engage in any advertising or marketing.</li>
            </ul>

            {/* ... Rest of the terms and conditions ... */}

            <h3 className="text-chiro-blue">12. Governing Law & Jurisdiction</h3>
            <p>
              These Terms will be governed by and interpreted in accordance with the laws of South Africa, and you submit to the non-exclusive jurisdiction of the state and federal courts located in South Africa for the resolution of any disputes.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}